import { useWeb3Modal } from "@web3modal/react";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CHAINS, SUPPORTED_CHAIN_IDS } from "../config/data";
import { chains } from "../config/web3.config";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/Navbar.module.scss";
import { CrossIcon, MenuIcon, NavLogo } from "./Icons";
import { Chain } from "wagmi";

const Navbar = (): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleNav = () => {
        setIsVisible(!isVisible);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [pointsLoading, setPointsLoading] = useState(false);
    const [points, setPoints] = useState<number>(0);
    const { isConnected, address, switchNetwork, chainId, selectedChain, setSelectedChain } = useWallet();
    const { open, setDefaultChain } = useWeb3Modal();

    const handleNetworkChange = async (_chainId: number) => {
        try {
            if (!isConnected && setSelectedChain) {
                // toast.error("Please connect your wallet to switch network.");
                // await handleConnect(_chainId);
                setSelectedChain(chains.find((chain) => chain.id == _chainId) as Chain);
                return;
            }
            if (_chainId === chainId) return;
            await switchNetwork?.(_chainId);
        } catch (error) {
            console.error("Error switching network:", error);
        }
    };

    const networkOptions = chains.map((chain) => ({
        label: chain.name,
        value: chain.id,
    }));

    const isCorrectChain = useMemo(() => {
        if (chainId && chains.map((c) => c.id).includes(chainId)) {
            return true;
        }
        return false;
    }, [chainId]);

    const handleLogoClick = () => {
        if (navigate) {
            if (location.pathname === "/stake") {
                navigate("/", {
                    replace: true,
                });
            } else {
                navigate("/");
            }
        }
    };

    const handleConnect = async (_chainId?: number) => {
        setLoading(true);
        if (isConnected && !isCorrectChain) {
            setDefaultChain?.(
                chains.find((c) => c.id === _chainId) ?? chains[0]
            );
            await handleNetworkChange(_chainId ?? chains[0].id);
        } else {
            await open();
        }
        setLoading(false);
    };

    const fetchPoints = async () => {
        setPointsLoading(true);
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/wallet/${address}`
            );
            setPoints(res.data.points);
        } catch (error) {
            console.error("Error fetching points:", error);
        }
        setPointsLoading(false);
    };

    useEffect(() => {
        if (isConnected && address) {
            fetchPoints();
        }
    }, [isConnected, address]);

    return (
        <div className={styles.navbarWrapper}>
            <div className={styles.banner}>
                <p>📢</p>
                <p>
                    Staking is now live on the Ethereum and Shibarium testnet.
                    Other features will be rolling out in phases.
                </p>
                <p>📢</p>
            </div>
            <div className={styles.navbar}>
                <div className={styles.logo} onClick={handleLogoClick}>
                    <NavLogo />
                </div>
                <div className={styles.links}>
                    <NavLink to="/">Stake</NavLink>
                    <NavLink to="/dashboard">Dashboard</NavLink>
                </div>
                <div className={styles.buttons}>
                    <div className={styles.points}>
                        Points: {pointsLoading ? 0 : points}
                    </div>
                    <div
                        className={`${styles.networkSelection} ${chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM
                            ? styles["networkSelection--red"]
                            : ""
                            }`}
                    >
                        <img
                            className={styles.networkImg}
                            src={
                                CHAINS?.[chainId]?.img ||
                                "https://chainlist.org/unknown-logo.png"
                            }
                            alt={CHAINS?.[chainId]?.name || "Unknown Network"}
                        />
                        <select
                            value={
                                isCorrectChain
                                    ? chainId ?? networkOptions[0].value
                                    : "Select Network"
                            }
                            onChange={(e) =>
                                handleNetworkChange(parseInt(e.target.value))
                            }
                        >
                            {networkOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                            {isCorrectChain ? (
                                <></>
                            ) : (
                                <option value="Select Network" disabled>
                                    Select Network
                                </option>
                            )}
                        </select>
                    </div>
                    {isConnected ? (
                        <button
                            onClick={() => handleConnect()}
                            className={styles.connect}
                        >
                            {isCorrectChain
                                ? address?.substring(0, 7) +
                                "..." +
                                address?.substring(address.length - 7)
                                : "Switch Network"}
                        </button>
                    ) : (
                        <button
                            onClick={() => handleConnect()}
                            className={styles.connect}
                        >
                            {isLoading ? "Loading..." : "Connect Wallet"}
                        </button>
                    )}
                </div>
                {/* MOBILE ACTIONS */}
                <div className={styles.mobileActions}>
                    {/* MOBILE - NETWORK SELECTION */}
                    <div
                        className={`${styles.networkSelection} ${chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM
                            ? styles["networkSelection--red"]
                            : ""
                            }`}
                    >
                        <img
                            className={styles.networkImg}
                            src={
                                CHAINS?.[chainId]?.img ||
                                "https://chainlist.org/unknown-logo.png"
                            }
                            alt={
                                CHAINS?.[chainId]?.name || "Unknown Network"
                            }
                        />
                        <select
                            value={
                                isConnected
                                    ? isCorrectChain
                                        ? chainId ?? networkOptions[0].value
                                        : "Select Network"
                                    : selectedChain?.id
                            }
                            onChange={(e) =>
                                handleNetworkChange(
                                    parseInt(e.target.value)
                                )
                            }
                        >
                            {networkOptions.map((option) => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            ))}
                            {isCorrectChain ? (
                                <></>
                            ) : (
                                <option value="Select Network" disabled>
                                    Select Network
                                </option>
                            )}
                        </select>
                    </div>
                    {/* MOBILE - WALLET CONNECTION */}
                    {isConnected ? (
                        <button
                            onClick={() => handleConnect()}
                            className={styles.connect}
                        >
                            {isCorrectChain
                                ? address?.substring(0, 4) +
                                "..." +
                                address?.substring(address.length - 4)
                                : "Switch Network"}
                        </button>
                    ) : (
                        <button
                            onClick={() => handleConnect()}
                            className={styles.connect}
                        >
                            {isLoading ? "Loading..." : "Connect"}
                        </button>
                    )}
                    <div className={styles.burger} onClick={toggleNav}>
                        {!isVisible ? <MenuIcon /> : <CrossIcon />}
                    </div>
                </div>
                <div
                    className={`${styles.menu} ${!isVisible ? styles.notVisible : ""
                        }`}
                >
                    <div className={styles.links}>
                        <NavLink to="/">Stake</NavLink>
                        <NavLink to="/dashboard">Dashboard</NavLink>
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.points}>
                            Points: {pointsLoading ? "..." : points}
                        </div>
                        <div
                            className={`${styles.networkSelection} ${chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM
                                ? styles["networkSelection--red"]
                                : ""
                                }`}
                        >
                            <img
                                className={styles.networkImg}
                                src={
                                    CHAINS?.[chainId]?.img ||
                                    "https://chainlist.org/unknown-logo.png"
                                }
                                alt={
                                    CHAINS?.[chainId]?.name || "Unknown Network"
                                }
                            />
                            <select
                                value={
                                    isConnected
                                        ? isCorrectChain
                                            ? chainId ?? networkOptions[0].value
                                            : "Select Network"
                                        : selectedChain?.id
                                }
                                onChange={(e) =>
                                    handleNetworkChange(
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                {networkOptions.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                                {isCorrectChain ? (
                                    <></>
                                ) : (
                                    <option value="Select Network" disabled>
                                        Select Network
                                    </option>
                                )}
                            </select>
                        </div>
                        {isConnected ? (
                            <button
                                onClick={() => handleConnect()}
                                className={styles.connect}
                            >
                                {isCorrectChain
                                    ? address?.substring(0, 7) +
                                    "..." +
                                    address?.substring(address.length - 7)
                                    : "Switch Network"}
                            </button>
                        ) : (
                            <button
                                onClick={() => handleConnect()}
                                className={styles.connect}
                            >
                                {isLoading ? "Loading..." : "Connect Wallet"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
