const sourceId = 157;

export const shibarium = {
    id: 157,
    name: "Puppynet",
    nativeCurrency: { name: "Bone", symbol: "BONE", decimals: 18 },
    rpcUrls: {
        public: {
            http: ["https://puppynet.shibrpc.com"],
        },
        default: {
            http: ["https://puppynet.shibrpc.com"],
        },
    },
    blockExplorers: {
        default: {
            name: "Puppyscan",
            url: "https://puppyscan.shib.io",
        },
    },
    testnet: true,
    sourceId,
};
