import { useMemo } from "react";
import {
    HEAD_DATA,
    STAKING_CONTRACTS_MAPPING,
    SUPPORTED_CHAIN_IDS,
    TOKENS,
} from "../config/data";
import { chains } from "../config/web3.config";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/Stake.module.scss";
import { ExternalLinkIcon } from "./Icons";
import StakeBox from "./StakeBox";

const Stake = (): JSX.Element => {
    const { chainId } = useWallet();

    const chain = useMemo(() => {
        const chainConfig = chains.find((c) => c.id === chainId);
        if (!chainConfig) return chains[0];
        return chainConfig;
    }, [chainId]);

    const addTokenToWallet = async (address: string) => {
        try {
            if (!window.ethereum) {
                return;
            }
            const rToken = TOKENS[address];
            const wasAdded = await (window.ethereum as any)?.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: rToken.address,
                        symbol: rToken.symbol,
                        decimals: rToken.decimals,
                        image: rToken.img,
                    },
                },
            });

            if (!wasAdded) {
                console.error("Token was not added to wallet");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={styles.stake}>
            <div className={styles.head}>
                <div className={styles.subhead}>
                    <h2>Staking</h2>
                    <p>Bury and claim tokens</p>
                </div>
                {/* <div className={styles.points}>Points: {"1,234"}</div> */}
                <div className={styles.utility}>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_paw ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_paw
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy PAW
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_steak ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_steak
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy STEAK
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    {chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM ? (
                        <></>
                    ) : (
                        // <button
                        //     onClick={() =>
                        //         addTokenToWallet(
                        //             HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                        //                 ?.add_paw ??
                        //                 HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                        //                     .add_paw
                        //         )
                        //     }
                        // >
                        //     Add PAW To Wallet
                        //     <i>
                        //         <PlusIcon />
                        //     </i>
                        // </button>
                        <></>
                    )}
                    {/* <button
                        onClick={() =>
                            addTokenToWallet(
                                HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                    ?.add_steak ??
                                    HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                                        .add_steak
                            )
                        }
                    >
                        Add STEAK To Wallet
                        <i>
                            <PlusIcon />
                        </i>
                    </button> */}
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.body}>
                {STAKING_CONTRACTS_MAPPING[chain.id].map((contract) => {
                    return (
                        <StakeBox
                            contractConfig={contract}
                            key={contract.address}
                            sToken={TOKENS[contract.sToken] ?? null}
                            rToken={TOKENS[contract.rToken] ?? null}
                            chain={chain.name ?? ""}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Stake;
