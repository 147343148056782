import { InjectedConnector } from "@wagmi/core";
import { publicProvider } from "@wagmi/core/providers/public";
import {
    Chain,
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createClient } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { shibarium } from "./chains";

export const chains = (
    process.env.REACT_APP_NODE_ENV === "development"
        ? [{ ...sepolia, rpcUrls: { ...sepolia.rpcUrls, default: { http: ["https://1rpc.io/sepolia"], webSocket: ["wss://ethereum-sepolia-rpc.publicnode.com"] } } }, shibarium]
        : [mainnet]
) as Chain[];
const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID as string;

const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId }), publicProvider({
    stallTimeout: 500,
})]);
export const wagmiClient = createClient({
    autoConnect: true,
    connectors: [...w3mConnectors({ projectId, version: 2, chains }), new InjectedConnector({
        chains
    })],
    provider,
    webSocketProvider,
});

export const ethereumClient = new EthereumClient(wagmiClient, chains);
