import { NavLink, useLocation } from "react-router-dom";
import styles from "../styles/Footer.module.scss";
import { DiscordIcon, FaqIcon, MediumLogo, TelegramLogo, XLogo } from "./Icons";

const Footer = (): JSX.Element => {
    const location = useLocation();

    const isFAQ = location.pathname.includes("faq");

    return (
        <div className={styles.footer}>
            <NavLink
                to="/faq"
                className={`${styles.faq} ${isFAQ ? styles.selected : ""}`}
            >
                FAQ <FaqIcon />
            </NavLink>
            <div className={styles.socials}>
                <NavLink
                    to="https://www.x.com/pawfiofficial"
                    className={styles.logo}
                    target="_blank"
                >
                    <XLogo />
                </NavLink>
                <NavLink
                    to="https://t.me/PawzoneCommunity"
                    className={styles.logo}
                    target="_blank"
                >
                    <TelegramLogo />
                </NavLink>
                <NavLink
                    to="https://discord.gg/tbcDsPc37t"
                    className={styles.logo}
                    target="_blank"
                >
                    <DiscordIcon />
                </NavLink>
                <NavLink
                    to="https://medium.com/@pawzone"
                    className={styles.logo}
                    target="_blank"
                >
                    <MediumLogo />
                </NavLink>
            </div>
            <div className={styles.banner}>2021-2024 PawZone Enterprises</div>
        </div>
    );
};

export default Footer;
