import { useWeb3Modal } from "@web3modal/react";
import axios from "axios";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useBalance, useContract } from "wagmi";
import { REFRESH_INTERVAL } from "../config/data";
import { chains } from "../config/web3.config";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/StakeBox.module.scss";
import { IWETH, PawFiStaking, TestToken } from "../types/contracts";
import { StakingContract, TokenContract } from "../types/staking.types";
import { formatNumber } from "../utils/formatter";
import Loader from "./Loader";

export type Props = {
    rToken: TokenContract | null;
    sToken: TokenContract;
    chain: string;
    contractConfig: StakingContract;
};

const StakeBox = (props: Props): JSX.Element => {
    const { contractConfig, sToken, rToken } = props;
    const { address: stakingContractAddress, abi } = contractConfig;

    const [isUserStakedLoading, setIsUserStakedLoading] = useState(false);
    const [isStakeLoading, setIsStakeLoading] = useState(false);
    const [isUnstakeLoading, setIsUnstakeLoading] = useState(false);
    const [isClaimLoading, setIsClaimLoading] = useState(false);
    const [isClaimableLoading, setIsClaimableLoading] = useState(false);
    const [isApyLoading, setIsApyLoading] = useState(false);

    const [amount, setAmount] = useState("");

    const [userStaked, setUserStaked] = useState("0");
    const [claimable, setClaimable] = useState("0");
    const [apy, setApy] = useState("0");

    const {
        address,
        signer,
        publicProvider,
        isConnected,
        chainId,
        switchNetwork,
    } = useWallet();
    const { open, setDefaultChain } = useWeb3Modal();

    const stakingContract = useContract({
        address: stakingContractAddress,
        abi,
        signerOrProvider: signer ?? publicProvider,
    }) as PawFiStaking;
    const stakedToken = useContract({
        address: sToken?.address ?? "",
        abi: sToken?.abi ?? [],
        signerOrProvider: signer ?? publicProvider,
    }) as TestToken | IWETH;

    const {
        data: sTokenBalance,
        isLoading: sTokenBalanceLoading,
        refetch: refetchsTokenBalance,
    } = useBalance({
        token: sToken.address as `0x${string}`,
        address: address as `0x${string}`,
        enabled: isConnected && !!sToken,
        watch: true,
        staleTime: 5000,
    });
    const {
        data: nativeBalance,
        isLoading: nativeBalanceLoading,
        refetch: refetchNativeBalance,
    } = useBalance({
        address: address as `0x${string}`,
        enabled: isConnected && !!sToken && sToken.isWrapped,
        watch: true,
        staleTime: 5000,
    });

    const totalBalance = useMemo(() => {
        return sToken?.isWrapped
            ? parseFloat(nativeBalance?.formatted ?? "0")
            : parseFloat(sTokenBalance?.formatted ?? "0");
    }, [nativeBalance, sTokenBalance]);

    const handleConnect = async (_chainId?: number) => {
        setDefaultChain?.(chains.find((c) => c.id === _chainId) ?? chains[0]);
        await open();
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "" || e.target.value === ".") {
            setAmount(e.target.value as any);
            return;
        }
        if (Number(e.target.value) < 0) {
            setAmount("0");
            return;
        }
        setAmount(e.target.value);
    };

    const handleMaxClick = async () => {
        if (!isConnected) {
            await handleConnect();
        }
        if (
            totalBalance === 0 &&
            (sTokenBalanceLoading || nativeBalanceLoading)
        ) {
            return;
        }
        setAmount(totalBalance.toString());
    };

    const handleStake = async () => {
        setIsStakeLoading(true);
        try {
            if (!isConnected) {
                await handleConnect();
            }
            if (contractConfig.chainId !== chainId) {
                toast.error(
                    "Invalid network. Please connect to correct network"
                );
                await switchNetwork?.(contractConfig.chainId);
            }
            const _amount = parseFloat(amount);
            if (stakedToken && stakingContract && signer && _amount > 0) {
                const requiredStakingFee = parseFloat(
                    ethers.utils.formatEther(await stakingContract.stakingFee())
                );
                if (sToken.isWrapped) {
                    if (_amount > parseFloat(sTokenBalance?.formatted ?? "0")) {
                        const requireWrappedSwap =
                            _amount -
                            parseFloat(sTokenBalance?.formatted ?? "0");

                        if (
                            requireWrappedSwap + requiredStakingFee >
                            parseFloat(nativeBalance?.formatted ?? "0")
                        ) {
                            toast.error(
                                `Insufficient ${
                                    sToken.nativeName
                                } balance ${formatNumber(
                                    nativeBalance?.formatted ?? "0",
                                    4
                                )} to stake!`
                            );
                            setIsStakeLoading(false);
                            return;
                        }
                        toast.info(
                            `Swapping ${formatNumber(requireWrappedSwap, 4)} ${
                                sToken.nativeName
                            } to ${sToken.symbol}`
                        );
                        const tx = await (stakedToken as IWETH).deposit({
                            value: ethers.utils.parseEther(
                                requireWrappedSwap.toPrecision(6)
                            ),
                        });
                        await tx.wait();
                    }
                } else {
                    if (totalBalance < _amount) {
                        toast.error("Insufficient balance to stake");
                        setIsStakeLoading(false);
                        return;
                    }
                }

                const allowance = await stakedToken.allowance(
                    address,
                    stakingContract.address
                );
                if (parseFloat(ethers.utils.formatEther(allowance)) < _amount) {
                    const tx = await stakedToken.approve(
                        stakingContract.address,
                        ethers.constants.MaxUint256
                    );
                    await tx.wait();
                    toast.success("Approve");
                }

                const tx = await stakingContract.stake(
                    ethers.utils.parseEther(_amount.toString()),
                    {
                        value: ethers.utils.parseEther(
                            requiredStakingFee.toPrecision(6)
                        ),
                    }
                );
                await tx.wait();
                await fetchUserStaked();
                if (sToken.isWrapped) await refetchNativeBalance();
                await refetchsTokenBalance();
                await fetchClaimable();
                toast.success("Successfully Buried");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error staking");
        }
        setIsStakeLoading(false);
    };

    const handleUnstake = async () => {
        if (!isConnected) {
            await handleConnect();
        }
        if (stakingContract && signer) {
            setIsUnstakeLoading(true);
            try {
                if (parseFloat(amount) > parseFloat(userStaked)) {
                    toast.error("Insufficient balance to unstake");
                    setIsUnstakeLoading(false);
                    return;
                }

                const requiredUnstakingFee = parseFloat(
                    ethers.utils.formatEther(
                        await stakingContract.unstakingFee()
                    )
                );
                if (
                    requiredUnstakingFee >
                    parseFloat(nativeBalance?.formatted ?? "0")
                ) {
                    toast.error(
                        `Insufficient ${
                            sToken.nativeName
                        } balance ${formatNumber(
                            nativeBalance?.formatted ?? "0",
                            4
                        )} to unstake!`
                    );
                    setIsUnstakeLoading(false);
                    return;
                }

                const tx = await stakingContract.withdraw(
                    ethers.utils.parseEther(amount.toString()),
                    {
                        value: ethers.utils.parseEther(
                            requiredUnstakingFee.toPrecision(6)
                        ),
                    }
                );
                await tx.wait();
                await fetchUserStaked();
                if (sToken.isWrapped) {
                    await (stakedToken as IWETH).withdraw(
                        ethers.utils.parseEther(amount.toString())
                    );
                    await refetchNativeBalance();
                }
                await refetchsTokenBalance();
                await fetchClaimable();
                toast.success("Successfully Dug Up");
            } catch (error) {
                console.error(error);
                toast.error("Error unstaking");
            }
            setIsUnstakeLoading(false);
        }
    };

    const handleClaim = async () => {
        if (!isConnected) {
            await handleConnect();
        }
        if (stakingContract && signer) {
            setIsClaimLoading(true);
            try {
                const tx = await stakingContract.getReward();
                await tx.wait();
                await fetchUserStaked();
                if (sToken.isWrapped) await refetchNativeBalance();
                await refetchsTokenBalance();
                await fetchClaimable();
                toast.success(
                    `Successfully claimed ${claimable} ${rToken?.symbol}!`
                );
            } catch (error) {
                console.error(error);
                toast.error("Error claiming");
            }
            setIsClaimLoading(false);
        }
    };

    const fetchUserStaked = async () => {
        setIsUserStakedLoading(true);
        try {
            if (stakingContract && signer) {
                const userStaked = await stakingContract.balanceOf(address);
                setUserStaked(ethers.utils.formatEther(userStaked));
            }
        } catch (error) {
            console.error(error);
        }
        setIsUserStakedLoading(false);
    };

    const fetchClaimable = async () => {
        setIsClaimableLoading(true);
        try {
            if (stakingContract && signer) {
                const claimable = await stakingContract.earned(address);
                setClaimable(ethers.utils.formatEther(claimable));
            }
        } catch (error) {
            console.error(error);
        }
        setIsClaimableLoading(false);
    };

    const fetchApy = async () => {
        setIsApyLoading(true);
        try {
            if (stakingContract) {
                // const sTokenUSDPriceRes = await axios
                //     .get(
                //         TOKENS[sToken.address].coinGeckoId
                //             ? `https://api.coingecko.com/api/v3/simple/price?ids=${
                //                   TOKENS[sToken.address].coinGeckoId
                //               }&vs_currencies=usd&precision=full`
                //             : `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${
                //                   TOKENS[sToken.address].address
                //               }&vs_currencies=usd&precision=full`,
                //         {
                //             headers: {
                //                 "x-cg-demo-api-key":
                //                     process.env.REACT_APP_COINGECKO_API_KEY,
                //             },
                //         }
                //     )
                //     .catch((error) => {
                //         console.error("Error fetching sToken price", error);
                //         return { data: {} };
                //     });

                // const sTokenUSDPrice =
                //     sTokenUSDPriceRes.data[
                //         TOKENS[sToken.address].coinGeckoId ??
                //             TOKENS[sToken.address].address
                //     ]?.usd;

                // const res = await axios.get(
                //     `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xC4c244F1dbCA07083feE35220D2169957c275e68&vs_currencies=usd&precision=full`,
                //     {
                //         headers: {
                //             "x-cg-demo-api-key":
                //                 process.env.REACT_APP_COINGECKO_API_KEY,
                //         },
                //     }
                // );
                // const steakPriceUSD =
                //     res.data["0xC4c244F1dbCA07083feE35220D2169957c275e68"]
                //         ?.usd ??
                //     (Object.values(res.data)[0] as any).usd ??
                //     0;

                const usersStakedRes = await axios
                    .get(`${process.env.REACT_APP_API_URL}/wallet/staked`)
                    .catch((error) => {
                        console.error(
                            "Error fetching users staked count",
                            error
                        );
                        return { data: {} };
                    });

                const rewardPerToken = await stakingContract.rewardPerToken();

                const apy =
                    (parseFloat(ethers.utils.formatEther(rewardPerToken)) *
                        100 *
                        365) /
                    usersStakedRes?.data;
                setApy(apy.toString());
            }
        } catch (error) {
            console.error(error);
        }
        setIsApyLoading(false);
    };

    useEffect(() => {
        if (signer) {
            fetchUserStaked();
            fetchClaimable();
        } else {
            setUserStaked("~");
            setClaimable("~");
        }
        fetchApy();
    }, [stakingContract, signer, publicProvider]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchClaimable();
        }, REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, [stakingContract, signer, publicProvider]);

    return (
        <div className={styles.stakeBox}>
            <div className={styles.header}>
                <div className={styles.token}>
                    <img src={sToken.img} alt={sToken.symbol} />
                    <div className={styles.name}>
                        <h3>
                            {sToken
                                ? sToken.isWrapped
                                    ? sToken?.nativeName
                                    : sToken?.symbol
                                : ""}
                        </h3>
                        <p>{props.chain}</p>
                    </div>
                </div>
                <div className={styles.details}>
                    <p className={styles.apy}>
                        <span>
                            {isApyLoading
                                ? "..."
                                : formatNumber(parseFloat(apy) / 1000, 3)}
                            {/* //TODO: change 1000 to no. of users */}%
                        </span>{" "}
                        APY
                    </p>
                    <p className={styles.stakes}>
                        Total Buried -{" "}
                        {isUserStakedLoading ? "..." : formatNumber(userStaked)}{" "}
                        {sToken?.isWrapped
                            ? sToken?.nativeName
                            : sToken?.symbol ?? ""}
                    </p>
                    <p className={styles.balance}>
                        Balance -{" "}
                        {sTokenBalanceLoading || nativeBalanceLoading
                            ? "..."
                            : formatNumber(totalBalance, 4)}{" "}
                        {sToken?.isWrapped
                            ? sToken?.nativeName
                            : sToken?.symbol ?? ""}
                    </p>
                </div>
            </div>
            <div className={styles.input}>
                <input
                    name="amount"
                    type="number"
                    placeholder="0"
                    value={amount}
                    onChange={handleAmountChange}
                    disabled={
                        isStakeLoading || isUnstakeLoading || isClaimLoading
                    }
                    readOnly={
                        isStakeLoading || isUnstakeLoading || isClaimLoading
                    }
                ></input>
                <div>
                    <button
                        className={styles.max}
                        type="button"
                        onClick={handleMaxClick}
                        disabled={
                            sTokenBalanceLoading ||
                            nativeBalanceLoading ||
                            isConnected
                                ? totalBalance <= 0
                                : false
                        }
                    >
                        MAX
                    </button>
                    <button
                        className={styles.stake}
                        type="button"
                        onClick={handleStake}
                        disabled={
                            isStakeLoading ||
                            isUnstakeLoading ||
                            isClaimLoading ||
                            !amount ||
                            parseFloat(amount) <= 0
                        }
                    >
                        {isStakeLoading ? <Loader /> : "BURY"}
                    </button>
                    <button
                        className={styles.stake}
                        type="button"
                        onClick={handleUnstake}
                        disabled={
                            isStakeLoading ||
                            isUnstakeLoading ||
                            isClaimLoading ||
                            !amount ||
                            parseFloat(amount) <= 0
                        }
                    >
                        {isUnstakeLoading ? <Loader /> : "DIG UP"}
                    </button>
                </div>
            </div>
            <button
                className={styles.claim}
                onClick={handleClaim}
                disabled={
                    isStakeLoading ||
                    isUnstakeLoading ||
                    isClaimLoading ||
                    isClaimableLoading ||
                    !claimable ||
                    parseFloat(claimable) <= 0
                }
            >
                <p>CLAIM</p>
                <div>
                    {isClaimableLoading ? (
                        <Loader />
                    ) : (
                        `${formatNumber(claimable)} ${rToken?.symbol ?? ""}`
                    )}
                </div>
            </button>
        </div>
    );
};

export default StakeBox;
