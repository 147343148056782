import { QueryClientProvider } from "react-query";
import { queryClient } from "..";
import { Dashboard, Faq, Stake } from "../components";

export default function Home({
    dashboard,
    faq,
}: {
    dashboard?: boolean;
    faq?: boolean;
}): JSX.Element {
    return (
        <QueryClientProvider client={queryClient}>
            {dashboard ? <Dashboard /> : faq ? <Faq /> : <Stake />}
        </QueryClientProvider>
    );
}
