import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useSigner } from "wagmi";
import { SUPPORTED_CHAIN_IDS } from "../config/data";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/DashboardBox.module.scss";
import { formatNumber } from "../utils/formatter";
import { Position } from "./Dashboard";
import Loader from "./Loader";

const DashboardBox = (props: {
    position: Position;
    steakPriceUSD: number;
    updatePosition?: (index: number, position: Position) => void;
    index?: number;
}): JSX.Element => {
    const {
        position: { token, stakingContract, staked, claimable, apy },
        steakPriceUSD,
    } = props;

    const { signer, chainId, switchNetwork } = useWallet();

    const { data: mainnet_signer } = useSigner({
        chainId: SUPPORTED_CHAIN_IDS.MAINNET,
    });
    const { data: sepolia_signer } = useSigner({
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
    });
    const { data: shibarium_signer } = useSigner({
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
    });

    const [isLoading, setLoading] = useState(false);

    const claimableUSD = useMemo(
        () => claimable * steakPriceUSD,
        [claimable, steakPriceUSD]
    );

    const handleClaim = async () => {
        setLoading(true);
        try {
            if (stakingContract.config.chainId !== chainId) {
                toast.error(
                    "Invalid network. Please connect to correct network"
                );
                await switchNetwork?.(stakingContract.config.chainId);
            }
            if (stakingContract && signer) {
                if (claimable === 0) {
                    toast.error("No rewards to claim");
                    return;
                }
                let _signer = signer;
                if (chainId === SUPPORTED_CHAIN_IDS.MAINNET && mainnet_signer) {
                    _signer = mainnet_signer;
                } else if (
                    chainId === SUPPORTED_CHAIN_IDS.SEPOLIA &&
                    sepolia_signer
                ) {
                    _signer = sepolia_signer;
                } else if (
                    chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM &&
                    shibarium_signer
                ) {
                    _signer = shibarium_signer;
                }
                if (!_signer) {
                    toast.error("Failed to claim rewards");
                    return;
                }
                const tx = await stakingContract.contract
                    .connect(_signer)
                    .getReward();
                await tx.wait();
                toast.success(`Claimed ${claimable} STEAK`);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to claim rewards");
        }
        setLoading(false);
    };

    return (
        <div className={styles.dashboardBox}>
            <div>
                <img src={token.img} alt={token.symbol} loading="lazy" />
                <p>{token.isWrapped ? token.nativeName : token.symbol}</p>
            </div>
            <p>{formatNumber(parseFloat(apy) / 1000, 4)}%</p>{" "}
            {/* //TODO: change 1000 to no. of users */}
            <p>
                {formatNumber(staked, 6)}{" "}
                {token.isWrapped ? token.nativeName : token.symbol}
            </p>
            <p>${!claimableUSD ? "..." : formatNumber(claimableUSD, 6)}</p>
            <button
                onClick={handleClaim}
                aria-disabled={
                    isLoading || stakingContract.config.chainId !== chainId
                }
            >
                {isLoading ? <Loader className={styles.loader} /> : "CLAIM"}
            </button>
        </div>
    );
};

export default DashboardBox;
