import { Chain, mainnet } from "wagmi";
import Steak_logo from "../assets/Small STEAK Token Logo (200 x 200 px).png";
import Eth_logo from "../assets/ethereum-eth-logo.png";
import Shibarium_logo from "../assets/shibarium_logo.png";
import { StakingContract, TokenContract } from "../types/staking.types";
import WETH from "./abi/IWETH.json";
import { default as StakingContract_Abi } from "./abi/PawFiStaking.json";
import { default as RewardToken_Abi } from "./abi/TestToken.json";

export enum SUPPORTED_CHAIN_IDS {
    MAINNET = 1,
    SEPOLIA = 11155111,
    SHIBARIUM = 157,
}

export const QUERY_LOGS_BLOCK_LIMITS: { [key in SUPPORTED_CHAIN_IDS]: number; } = {
    [SUPPORTED_CHAIN_IDS.MAINNET]: 10000,
    [SUPPORTED_CHAIN_IDS.SEPOLIA]: 10000,
    [SUPPORTED_CHAIN_IDS.SHIBARIUM]: 50000,
}

export enum FEES {
    STAKE = 0.6,
    UNSTAKE = 0.3,
}

export const CHAINS: {
    [key: number]: Chain & { img: string; };
} = {
    [SUPPORTED_CHAIN_IDS.MAINNET]: { ...mainnet, img: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg" },
    [SUPPORTED_CHAIN_IDS.SEPOLIA]: { ...mainnet, img: "https://chainlist.org/unknown-logo.png" },
    [SUPPORTED_CHAIN_IDS.SHIBARIUM]: { ...mainnet, img: Shibarium_logo },
};

export const TOKENS: { [key: string]: TokenContract; } = {
    "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136": {
        address: "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
        chainId: SUPPORTED_CHAIN_IDS.MAINNET,
        decimals: 18,
        symbol: "PAW",
        name: "PAW",
        img: "https://etherscan.io/token/images/pawzone2_32.png",
    },
    "0xC4c244F1dbCA07083feE35220D2169957c275e68": {
        address: "0xC4c244F1dbCA07083feE35220D2169957c275e68",
        chainId: SUPPORTED_CHAIN_IDS.MAINNET,
        decimals: 18,
        symbol: "STEAK",
        name: "STEAK",
        img: "https://www.pawzone.io/img/token/steak-img.png",
    },
    "0xb1c7b5562a0190e3f7bac76888a3f8b7945f1a02": {
        address: "0xb1c7b5562a0190e3f7bac76888a3f8b7945f1a02",
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
        decimals: 18,
        symbol: "STEAK",
        name: "STEAK",
        img: "https://www.pawzone.io/img/token/steak-img.png",
    },
    "0x3b580AdA7F3332f78d2dFd93484fF0ec18dfe868": {
        address: "0x3b580AdA7F3332f78d2dFd93484fF0ec18dfe868",
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
        decimals: 18,
        symbol: "STEAK",
        name: "STEAK",
        img: "https://www.pawzone.io/img/token/steak-img.png",
    },
    "0x6f6914322E245BAE4b77C261295FBE5836Dc2499": {
        address: "0x6f6914322E245BAE4b77C261295FBE5836Dc2499",
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
        decimals: 18,
        symbol: "rTEST",
        name: "Reward TEST",
        img: "https://picsum.photos/50",
        abi: RewardToken_Abi.abi,
    },
    "0xfbDc7b18073Ad960190E60AefB69cBC3BF2c29bE": {
        address: "0xfbDc7b18073Ad960190E60AefB69cBC3BF2c29bE",
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
        decimals: 18,
        symbol: "rTEST",
        name: "Reward TEST",
        img: "https://picsum.photos/50",
        abi: RewardToken_Abi.abi,
    },
    "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9": {
        address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
        decimals: 18,
        symbol: "WETH",
        isWrapped: true,
        nativeName: "ETH",
        coinGeckoId: "ethereum",
        name: "Wrapped Ether",
        img: Eth_logo,
        abi: WETH.abi,
    },
    "0x0C477AE2E8E5ce1666468e44b066542Fa46f9F72": {
        address: "0x0C477AE2E8E5ce1666468e44b066542Fa46f9F72",
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
        decimals: 18,
        symbol: "WETH",
        isWrapped: true,
        nativeName: "ETH",
        coinGeckoId: "ethereum",
        name: "Wrapped Ether",
        img: Eth_logo,
        abi: WETH.abi,
    },
    "0x41c3F37587EBcD46C0F85eF43E38BcfE1E70Ab56": {
        address: "0x41c3F37587EBcD46C0F85eF43E38BcfE1E70Ab56",
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
        decimals: 18,
        symbol: "WBONE",
        isWrapped: true,
        nativeName: "BONE",
        coinGeckoId: "bone-shibaswap",
        name: "Shibarium Wrapped BONE",
        img: Steak_logo,
        abi: WETH.abi,
    },
}

export const STAKING_CONTRACTS: StakingContract[] = [
    {
        address: "0x9FccFB5e8524D4f16541cFC01aC7357b649610D5",
        fromBlock: 5683512,
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
        sToken: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
        rToken: "0xb1c7b5562a0190e3f7bac76888a3f8b7945f1a02",
        totalReward: "14000",
        disabled: false,
        abi: StakingContract_Abi.abi,
        duration: 120,
    },
    {
        address: "0x9FccFB5e8524D4f16541cFC01aC7357b649610D5",
        fromBlock: 1939644,
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
        sToken: "0x41c3F37587EBcD46C0F85eF43E38BcfE1E70Ab56",
        rToken: "0x3b580AdA7F3332f78d2dFd93484fF0ec18dfe868",
        totalReward: "14000",
        disabled: false,
        abi: StakingContract_Abi.abi,
        duration: 120,
    },
]

export const STAKING_CONTRACTS_MAPPING: { [key: number]: StakingContract[] } = {}

for (const contract of STAKING_CONTRACTS) {
    if (!STAKING_CONTRACTS_MAPPING[contract.chainId]) {
        STAKING_CONTRACTS_MAPPING[contract.chainId] = []
    }
    STAKING_CONTRACTS_MAPPING[contract.chainId].push(contract)
}

export const REFRESH_INTERVAL = 1000 * 30; // 30 seconds

export const HEAD_DATA = {
    [SUPPORTED_CHAIN_IDS.MAINNET]: {
        buy_paw: "https://shibaswap.com/swap",
        buy_steak: "https://shibaswap.com/swap",
        add_paw: "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
        add_steak: "0x0124634e7a6325babbbe39fed7d7f9a6bce2c88f",
    },
    [SUPPORTED_CHAIN_IDS.SEPOLIA]: {
        buy_paw: "https://shibaswap.com/swap",
        buy_steak: "https://shibaswap.com/swap",
        add_paw: "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
        add_steak: "0xb1c7b5562a0190e3f7bac76888a3f8b7945f1a02",
    },
    [SUPPORTED_CHAIN_IDS.SHIBARIUM]: {
        buy_paw: "https://shibaswap.com/swap",
        buy_steak: "https://chewyswap.dog/swap/?chain=shibarium",
        add_paw: "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136",
        add_steak: "0x3b580AdA7F3332f78d2dFd93484fF0ec18dfe868",
    },
}