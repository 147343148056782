export const getWholeAndDecimal = (value: string) => {
    const guardNaN = (value: any) => isFinite(value) ? value : 0;
    const [whole, decimal] = String(value).split('.');
    return [Number(guardNaN(whole)), guardNaN(decimal)];
}

export function formatNumber(num: number | string, truncate?: number): string {
    if (typeof num === 'string') {
        num = parseFloat(num)
    }
    let [whole, decimal] = getWholeAndDecimal(num.toString());

    if (parseFloat(`0.${decimal}`) === 0) {
        return whole.toLocaleString();
    }

    if (truncate && decimal.length > truncate) {
        decimal = decimal.slice(0, truncate);
    }

    return `${whole.toLocaleString()}.${decimal}`;
}